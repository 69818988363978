html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
}

code {
  font-family: 'Inter', sans-serif;
}

@font-face {
  font-family: 'Noteworthy';
  src: local('Noteworthy'), url('../src/assets/fonts/Noteworthy.ttf') format('tff');
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0 !important;
  margin: 0 !important;
}

*,
div,
*:before,
*:after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -ms-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

/* @media screen and (max-width: 1023px) {
  #container {
    display: none;
  }
}

.screen-size-message {
  display: flex;
  color: #2d506c;
  font-size: 18px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
  height: 100vh;
  padding: 2rem;
  text-align: center;
}

@media screen and (min-width: 1023px) {
  .screen-size-message {
    display: none;
  }
} */

/* html {
  overflow: scroll;
  overflow-x: hidden;
} */
/* ::-webkit-scrollbar { */
/* width: 0;   */
/* Remove scrollbar space */
/* background: transparent;   */
/* Optional: just make scrollbar invisible */
/* } */
/* Optional: show position indicator in red */
/* ::-webkit-scrollbar-thumb {
  background: transparent;
} */

@keyframes shake {
  0% {
    transform: translateX(0)
  }

  25% {
    transform: translateX(25px);
  }

  50% {
    transform: translateX(-25px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes spaceboots {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(0px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(2px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(2px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.shake:hover {
  animation-name: spaceboots;
  animation-duration: 0.8s;
  transform-origin: 50% 50%;
  /* animation-iteration-count: infinite; */
  animation-timing-function: linear;
}

a {
  text-decoration: none !important;
}

.wave {
  animation-name: wave-animation;
  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;
  /* Change to speed up or slow down */
  animation-iteration-count: infinite;
  /* Never stop waving :) */
  transform-origin: 70% 70%;
  /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0.0deg)
  }

  10% {
    transform: rotate(14.0deg)
  }

  /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8.0deg)
  }

  30% {
    transform: rotate(14.0deg)
  }

  40% {
    transform: rotate(-4.0deg)
  }

  50% {
    transform: rotate(10.0deg)
  }

  60% {
    transform: rotate(0.0deg)
  }

  /* Reset for the last half to pause */
  100% {
    transform: rotate(0.0deg)
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: red;
  z-index: 97979879897987989798;
}

#id-177-title{
  display:none !important;
}